/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Mobile Menu
        $(function() {
            // Bind a click event to anything with the class "toggle-nav"
            $('.navbar-toggle').click(function() {
                $('body').toggleClass('show-nav');
                this.classList.toggle('active');
                return false;
            });
            // Close nav on click of anything but a link
            $('.mobile-nav').click(function() {
                $('body').removeClass('show-nav');
                this.classList.toggle('active');
            });
        });

        // Activate LetteringJS for footer text color
        $('.lettering-js').lettering('words');

        // Activate validation on newsletter form
        $('.newsletter-signup').validate();

        // Newsletter form AJAX functionality
        $('.newsletter-signup').ajaxChimp({
          url: 'http://818agency.us7.list-manage.com/subscribe/post?u=a62bfb74bc884251916c78bf0&amp;id=7bc7851e51',
          callback: function callbackFunction (resp) {
            if (resp.result === 'success') {
              $('.newsletter-signup').html('<h4>Subscribed! Check your inbox to confirm.</h4>');
            }
          }
        });
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {
        $( '.arrow-down' ).on('click', function(event) {
          event.preventDefault();
          $('html, body').animate({
              scrollTop: $('.home-process').offset().top
          }, 400);
        });
      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // Contact page
    'contact': {
      init: function() {

        // Style Interest dropdown
        $('#mce-INTEREST').prepend('<option class="placeholder" selected="selected" disabled>I&#39;M INTERESTED IN</option>');
        $('#mce-INTEREST').click(function(){
           $('#mce-INTEREST').addClass('selected');
        });

        //Activate form validation and US phone checking on contact form
        $('#contactForm').validate({
          rules: {
            contactPhone: {
              required: true,
              phoneUS: true
            }
          }
        });

        // Contact form AJAX functionality
        $('#contactForm').ajaxChimp({
          url: 'http://818agency.us7.list-manage.com/subscribe/post?u=a62bfb74bc884251916c78bf0&amp;id=55227846d9',
          callback: function callbackFunction (resp) {
            if (resp.result === 'success') {
              $('#contactForm').html('<h3>We received your contact request. Please confirm your email address, and someone will be in touch soon.</h3>');
            }
          }
        });
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
